import React from 'react'
import styled from 'styled-components'
import Layout from '../../layout'
import Page from './page-layout'
import Carousel from '../../carousel'
import GoHome from '../../gohome.js'

const PageTemplate = ({
  lang,
  page,
  title,
  description,
  image,
  otherLangSlug,
}) => {
  // const lang = location.pathname.startsWith('/en/') ? 'en' : 'it'
  
  const slides = []
  const selectItem = (item, field) => {
    var selected = item[field]
    return selected
  }
  const addImage = (item, otherLang) => {
    // const l = otherLang ? (lang === 'en' ? 'it' : 'en') : lang
    slides.push({
      img: selectItem(item, 'image').gatsbyImageData || null,
      label: selectItem(item, otherLang ? 'strangerLabel' : 'label') || null,
    })
  }

  if (page.images && page.images.length) {
    page.images.map((item) => {
      addImage(item)
    })
  }

  const Info = styled.dl`
    width: 16vw;
    margin: 60px auto 20px auto;
    padding-bottom: 30px;
    font-weight: 300;
  `
  const SideText = styled.p`
    font-size: 1rem;
    font-family: 'Nunito';
    margin-inline-start: auto;
  `
  const Cite = styled.blockquote`
    font-size: 1.3rem;
    font-family: 'Nunito';
//    font-style: italic;

    /* position: relative;
  font-size: 1.3rem;
  font-family: 'Nunito';
  font-style: italic;
  margin-inline-start: auto;
  padding-left: 2rem;
  &:before {
    content: '\\201C';
    font-size: 6rem;
    top: -50%;
    left: -1.5rem;
    position: absolute;
  }
  &:after {
    content: '\\201D';
    font-size: 6rem;
    right: 0rem;
    margin-top: -2rem;
    position: absolute;
  } */
  `
  const side = (
    <>
      <Carousel
        // slideWidth={200}
        // slideGap={10}
        {...{ lang }}
        {...{ slides }}
      />
      {page.sideText && (
        <Info>
          {page.sideTextIsACitation ? (
            <Cite
              dangerouslySetInnerHTML={{
                __html: page.sideText.childMarkdownRemark.html,
              }}
            />
          ) : (
            <SideText
              dangerouslySetInnerHTML={{
                __html: page.sideText.childMarkdownRemark.html,
              }}
            />
          )}
        </Info>
      )}
    </>
  )

  const Header = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  `
  const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    color: black;
    flex: 3;
    margin: 0;
    z-index: 100;
    position: relative;
    font-size: 2.5rem;
    @media (min-width: 1400px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1600px) {
      font-size: 3.4rem;
    }
    @media (max-width: 1200px) {
      font-size: 2.3rem;
    }
    @media (max-width: 1100px) {
      font-size: 2.2rem;
      flex-direction: column;
    }
  `
  const Content = styled.div`
    flex: 1;
    flex-grow: unset;
    line-height: 170%;
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    @media (min-width: 1400px) {
      font-size: 1.4rem;
    }
    @media (min-width: 1600px) {
      font-size: 1.7rem;
    }
    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1100px) {
      font-size: 1rem;
      flex-direction: column;
    }
  `
  // console.log(page)
  const main = (
    <>
      <GoHome {...{ lang }} />
      {page.showTitle && (
        <Header>
          <Title>{page.title}</Title>
        </Header>
      )}
      <Content className="content">
        {page.content && page.content.childMarkdownRemark && (
          <div
            dangerouslySetInnerHTML={{
              __html: page.content.childMarkdownRemark.html,
            }}
          />
        )}
      </Content>
    </>
  )
  // const secondary = <>cc</>

  return (
    <Layout
      {...{ title }}
      {...{ description }}
      {...{ image }}
      {...{ otherLangSlug }}
    >
      <Page
        {...{ side }}
        {...{ main }}
        // {...{ secondary }}
      />
    </Layout>
  )
}
export default PageTemplate
