import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { opening } from './animations'

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  width: auto;
  min-height: 3vh;
  top: 1vh;
  left: 23vw;
  line-height: 1em;
  /* transform-origin: 100% 100%; */
  @media (max-width: 700px) {
    left: 10vw;
  }
`
const Item = styled.li`
  display: inline-block;
  margin: 0;
`
const Txt = styled.h2`
  font-weight: 300;
  font-family: 'Lato';
  -webkit-letter-spacing: 0.3rem;
  -moz-letter-spacing: 0.3rem;
  -ms-letter-spacing: 0.3rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  color: #000;
  font-size: 1.1rem !important;
  @media (min-width: 1400px) {
    font-size: 1.3rem;
  }
  @media (min-width: 1600px) {
    font-size: 1.6rem;
  }
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) {
    font-size: 0.9rem;
    flex-direction: column;
  }
  &:hover {
    color: #c6091c;
  }
`
const GoHome = ({ lang }) => {
  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
  }

  return (
    <List>
      <Item>
        <StyledLink to={lang === 'en' ? '/en/' : '/'}>
          <Txt>Andrea Martines</Txt>
        </StyledLink>
      </Item>
    </List>
  )
}
export default GoHome
