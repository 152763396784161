import React from 'react'
import { graphql } from 'gatsby'
import PageTemplate from './page/page-template'
import { setCookie, getCookie } from '../../../common/utils'

const Page = (props) => {
  const page = props.data.contentfulPage
  const lang = props.pageContext.lang.substr(0, 2)
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    if (page.description === 'copyright') {
      window.setTimeout(() => {
        const cookieConsent = `<label class="switch"><input id="toggle-cookie-consent" type="checkbox" onChange="console.log('${
          getCookie('gdpr-consent') === 'true' ? ' true' : 'false'
        }')"${
          getCookie('gdpr-consent') === 'true' ? ' checked' : ''
        }><span class="slider round"></span></label>&nbsp;&nbsp;${
          lang === 'it' ? 'Accetto i cookie' : 'I accept cookies'
        }`
        document.getElementById('cookie-consent').innerHTML = cookieConsent
        const switcher = document.getElementById('toggle-cookie-consent')
        switcher.addEventListener('change', (event) => {
          if (event.currentTarget.checked) {
            setCookie('gdpr-consent', true)
          } else {
            setCookie('gdpr-consent', false)
          }
        })
      }, 1000)
    }
  }

  return (
    <PageTemplate
      {...{ page }}
      title={`${page.title} - Andrea Martines`}
      description={page.description}
      image=""
      lang={lang}
      otherLangSlug={props.pageContext.otherLangSlug}
    />
  )
}
export default Page

export const pageQuery = graphql`
  query PageBySlug1($id: String!, $lang: String!) {
    contentfulPage(contentful_id: { eq: $id }, node_locale: { eq: $lang }) {
      title
      showTitle
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      images {
        label
        strangerLabel
        image {
          gatsbyImageData(placeholder: BLURRED, width: 1000)
        }
      }
      sideText {
        childMarkdownRemark {
          html
        }
      }
      sideTextIsACitation
      description
    }
  }
`
