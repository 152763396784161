import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { almostFadeIn, almostFadeOut } from './animations'

const isBrowser = typeof window !== 'undefined'

const Container = styled.div`
  overflow-y: visible;
`
const Slideshow = styled.div`
  display: table;
  overflow-x: auto;
  overflow-y: visible;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  border-radius: 5px;
`

// const Carousel = ({ lang, slides, slideWidth, slideGap }) => {
const Carousel = ({ lang, slides }) => {
  // const slideFull = slideWidth + slideGap * 4
  const slideWidth = 18
  const Inner = styled.div`
    position: relative;
    /* width: ${slideWidth + slideGap * 2}px; */
    width: ${slideWidth}vw;
    height: 100%;
    overflow-x: auto;
    overflow-y: visible;
    scroll-snap-type: x mandatory;
    @media (max-width: 700px) {
      width: 54vw;
    }
  `
  const Slide = styled.div`
    display: table-cell;
    position: relative;
    overflow-y: visible;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    font-size: 1.5rem;
    /* min-width: ${slideWidth + slideGap * 2}px; */
    /* min-width: 18vw; */
    vertical-align: top;
    /* padding: 0 ${slideGap}px; */
    padding: 0 1vw;
    height: inherit;
  `
  const SlideInner = styled.div`
    position: relative;
    /* background: yellow; */
    height: fit-content;
    width: fit-content;
    @media (max-width: 700px) {
      width: 54vw;
    }
  `
  const Image = styled(GatsbyImage)`
    display: flex;
    align-items: flex-start;
    /* width: ${slideWidth}px; */
    width: 100%;
  `
  const Info = styled.div`
    position: absolute;
    display: block;
    top: 0;
    opacity: 0;
    background: white;
    height: 100%;
    width: 100%;
    &.open {
      animation: ${almostFadeIn} 0.3s ease-in-out 0s both;
    }
    &.closed {
      animation: ${almostFadeOut} 0.3s ease-in-out 0s both;
    }
  `
  const Label = styled.div`
    display: inline-block;
    overflow-y: visible;
    text-align: center;
    /* width: ${slideWidth}px; */
    width: ${slideWidth - 2}vw;
    padding-top: 10px;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 1.1rem;
    @media (max-width: 700px) {
      width: 54vw;
    }
  `
  const Nav = styled.div`
    position: relative;
    /* width: ${slideWidth + slideGap * 2}px; */
    width: 100%;
    height: 20px;
    text-align: center;
    z-index: 1111111;
  `
  const NavButton = styled.input.attrs({
    type: 'radio',
    name: 'slider',
    class: 'slider__nav',
  })`
    width: 12px;
    height: 12px;
    margin: 1rem 12px;
    border-radius: 50%;
    z-index: 10;
    outline: 6px solid #ccc;
    outline-offset: -6px;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    &:checked {
      outline: 6px solid #777;
    }
  `
  if (isBrowser) {
    const needCarousel = slides.length > 1
    var slideFull
    needCarousel &&
      window.setTimeout(() => {
        document.getElementById('nav-1').checked = true
        slideFull = document.getElementById('slideshow').offsetWidth
      }, 200)
  }

  const goto = (slide) => {
    document.getElementById('slideshow').scrollLeft = slideFull * (slide - 1)
  }
  const getScroll = () => {
    const scrollLeft = document.getElementById('slideshow').scrollLeft
    const slideNum = document.getElementById(
      `nav-${scrollLeft / slideFull + 1}`
    )
    if (slideNum) {
      slideNum.checked = true
    }
  }
  const toggleInfo = (w) => {
    return;
    const el = document.getElementById(`info-${w}`)
    if (el.classList.contains('open')) {
      el.classList.add('closed')
      el.classList.remove('open')
    } else {
      el.classList.remove('closed')
      el.classList.add('open')
    }
  }
  return (
    <Container>
      <Slideshow>
        <Inner id="slideshow" onScroll={() => getScroll()}>
          {slides.length > 0 &&
            slides.map((item, index) => {
              return (
                <Slide id={`slide-${index + 1}`}>
                  <SlideInner
                    id={`card-${index + 1}`}
                    onClick={() => toggleInfo(index + 1)}
                  >
                    <Image alt="" image={item.img} />
                    <Info id={`info-${index + 1}`}>aaa</Info>
                  </SlideInner>
                  {slides.length > 1 && <Label>{item.label}</Label>}
                </Slide>
              )
            })}
        </Inner>
        {slides.length > 1 && (
          <Nav>
            {slides.map((item, index) => {
              const slideId = `nav-${index + 1}`
              return <NavButton id={slideId} onClick={() => goto(index + 1)} />
            })}
          </Nav>
        )}
      </Slideshow>
    </Container>
  )
}
export default Carousel
