import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { opening, downFade, appear1, newPageMain } from '../../animations'
import Layout from '../../layout'

const Page = ({ projects, lang, side, main, secondary, options }) => {
  const Container = styled.section`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    scroll-snap-align: start;
    padding-top: 10vh;
    z-index: 98;
    @media (max-width: 700px) {
      justify-content: center;
    }
  `
  const Box = styled.div`
    width: 95vw;
    display: flex;
    flex-direction: row;
    @media (min-width: 1000px) {
      height: 60vh;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      width: 80vw;
    }
  `
  const SideBg = styled.div`
    position: fixed;
    width: 20vw;
    height: 400%;
    z-index: 120;
    background: ${options && options.sideBg || '#a6b5a2'};
    animation: ${newPageMain} 0.4s ease-in-out 0s both;
    @media (max-width: 700px) {
      display: none;
    }
  `
  const Side = styled.div`
    /* flex: 2; */
    align-items: flex-start;
    /* margin-left: 1.5vw; */
    min-width: 20vw;
    height: 100%;
    z-index: 130;
    /* background: #ccc; */
    @media (max-width: 700px) {
      order: 2;
    }
  `
  const Main = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    width: inherit;
    /* flex: 8; */
    z-index: 100;
    padding: 0 2vw 0 3vw;
    &.visible {
      /* animation: ${opening} 1s ease-in-out 0s both; */
    }
    @media (max-width: 700px) {
      order: 1;
      padding: 0;
    }
  `
  const Secondary = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    /* flex: 1; */
    z-index: 130;
    /* background: yellow; */
    min-width: 15vw;
    @media (max-width: 700px) {
      order: 3;
    }

    /* padding: 0 2vw 0 4vw; */
  `
  return (
    <motion.div
      key="/"
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -400 }}
      transition={{
        type: 'spring',
        mass: 0.35,
        stiffness: 75,
        // duration: 5.3,
      }}
    >
      <Container>
        <SideBg />
        <Box>
          <Side>{side}</Side>
          <Main>{main}</Main>
          <Secondary>{secondary}</Secondary>
        </Box>
      </Container>
    </motion.div>
  )
}
export default Page
